@use '@chicksgroup/styles/colors';
@use '@chicksgroup/styles/mixins' as m;
@import 'styles/variables';

.navbar {
    padding: 0 !important;
    height: 64px;

    @media (width <= $tablet) {
        height: 60px;
    }
}

nav {
    position: relative;

    &.navbar-transparent {
        background: $color-grey-100 !important;
        border-bottom: unset !important;
    }

    &.navbar-border-bottom::after {
        position: absolute;
        content: '';
        left: 0%;
        top: calc(99% - 1px);
        width: 100%;
        height: 1px;
        background-color: $gray-input-focused;
        transform: translateY(1px);
        pointer-events: none;
    }

    &.navbar-border-bottom::after, .blue-border-bottom {
        position: absolute;
        content: '';
        left: 0%;
        top: 99%;
        width: 100%;
        height: 1px;
        background-color: $color-grey-400;
    }
}

.fixed-top {
    position: fixed !important;

    &:not(:has(.navbar-transparent)) {
        border-bottom: 1px solid rgba(196, 196, 196, 0.3);
    }
}

.border-less {
    border-bottom: 0 !important;
}

.navbar-content {
    a:not(.link),
    .user-info .btn {
        color: $navbar-text-purple !important;
        &:not(.material-icons) {
            font-size: $regular;

            @media (max-width: $desktop) {
                font-size: $small;
                margin-right: $largeplus;
            }
        }
    }
}

.navbar-content-inverted {
    a:not(.link),
    .user-info .btn {
        color: var(--cg-grey-100) !important;

        &:not(.material-icons) {
            font-size: $regular;

            @media (max-width: $desktop) {
                font-size: $medium;
                margin-right: $largeplus;
            }
        }
    }
}

.navbar-logo {
    filter: invert(100%) sepia(82%) saturate(1026%) hue-rotate(190deg) brightness(1000%) contrast(100%) !important;

    &:hover {
        filter: invert(100%) sepia(82%) saturate(1026%) hue-rotate(180deg) brightness(90%) contrast(100%) !important;
    }
}

.navbar-btn, .inverted-navbar-btn {
    width: 114px !important;
    height: 44px !important;
    padding: 12px 16px !important;
    background: $purple-text;

    >div {
        @include square(100%);
        gap: 9px;
        align-items: center;

        span {
            font-weight: 500;
        }

        .material-icons {
            @include square(24px);
            margin-top: 0;
        }
    }

    @media (width <= $phone) {
        padding: 0 $huge;
    }

    &:hover {
        background: $cx-primary-hover;
    }
}

#navigation-bar {
    .primary-inverted {
        min-height: $extrahugetitle;
        width: 138px;
        padding: 0 33px;

        @media (max-width: $phone) {
            min-height: 31px;
            width: $giant;
            padding: 0 $huge;
        }
    }

    .container-nav {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 0 51px 0 28px;

        @media (width <= $desktop) {
            padding-inline: 0;
        }

        .dropdowns {
            margin-left: 0;

            a {
                height: 100%;
                margin-inline: $morehuge;
                padding: 0;
                align-items: center;
            }

            > a {
                margin-left: 15px;
            }
        }
    }

    .left-container {
        display: flex;

        hr {
            width: $extrahugetitle;
            transform: rotate(90deg);
            margin-top: $largeplus;
            height: 0;
            color: $cx-purple;
            opacity: 1;

            @media (max-width: $tablet) {
                width: 100%;
                transform: none;
            }
        }

        .navbar-brand {
            width: 210px;
            padding: 18px 0 18px 24px;
            margin-right: 0 !important;

            @media (width <= $desktop) {
                width: 114px;
                padding: 18px 0 18px 10px;
            }
        }

        #chicksx-nav-logo {
            width: 152px;
            height: 100%;

            @media (width <= $tablet) {
                width: 118px;
                height: 38px;
            }
        }

        @media (width <= $desktop) {
            padding-left: 18px;
        }
    }

    #cx-text-input {
        width: 100%;
        height: 52px;

        .mdc-select, .mdc-text-field {
            .mdc-floating-label {
                &--float-above {
                    transform: translateY(-79%) translateX(-10%) scale(.8571) !important;
                }
            }
        }
      
        .cx-search-input {
          background-color: $color-grey-200 !important;
          border-radius: 4px;
          box-shadow: none;

          .mdc-text-field:not(.mdc-text-field--disabled)
            .mdc-text-field__icon--leading,
          .mdc-text-field__input::placeholder {
            color: $color-grey-600;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            opacity: 0.3;
            letter-spacing: 0.035px;
          }

          &:hover, &:focus {
            outline: $cx-purple 1px solid;
            background-color: var(--cg-grey-300) !important;
          }

          &.mdc-text-field--focused {
            outline: $cx-purple 2px solid;
            background-color: var(--cg-grey-350) !important;
          }
        }

        .mdc-text-field:not(.mdc-text-field--disabled)
          .mdc-text-field__icon--leading {
          color: $color-grey-700;
          @include square(20px);
          margin-right: 8px;
          margin-bottom: 7px;
        }

        .mdc-text-field:not(.mdc-text-field--disabled)
          .mdc-text-field__icon--trailing {
            color: var(--cg-grey-700);
            width: 16px;
            font-size: 16px;
            margin-right: 19px;
            padding: 2px;
        }
    }

    @media (width <= $desktop) {
        .mdc-text-field .mdc-floating-label {
            font-size: $regular;
        }
    }
}

.inverted-navbar-btn {
    font-weight: 700;
    background: $primary-300 !important;

    &:hover {
        background: var(--cx-primary-400) !important;
    }

    &:active {
        background: var(--cx-primary-500) !important;
    }
}

#cx-notification-bell {
    margin-right: 18px;

    @media (width <= $desktop) {
        margin-right: 7px;
    }
}

.navbar-content {
    color: $navbar-text-purple;

    & .notification-badge {
        background-color: $cx-purple;
        color: $white;
        z-index: 99;
        border: 1px solid $cx-purple;
    }

    .sign-in-button {
        margin-inline: $small;
    }
}

.navbar-btn,
.inverted-navbar-btn,
.navbar-content .sign-in-button,
.navbar-content-inverted .sign-in-button {
    @media (width <= $desktop) {
        font-size: $medium !important;
    }
}

.navbar-content-inverted {
    color: var(--cg-grey-100);

    .notification-badge {
        color: $cx-purple;
        background-color: var(--cg-grey-100);
        z-index: 99;
        border: 1px solid var(--cg-grey-100);
    }

    .sign-in-button {
        margin-inline: $small;
    }

    #cx-currency-dropdown .btn {
        color: var(--cg-grey-100) !important;
    }
}

.navbar-toggler-icon {
    @include square($extralarge !important);
    background-image: unset !important;
    color: var(--cg-grey-100);
}

.navbar-toggler {
    border: none !important;
    padding: 5px 2px !important;
    margin-top: 4px !important;
    pointer-events: all;

    --cds-icon-color: #{$purple-text};
    --md-icon-size: 24px;

    &:focus {
        box-shadow: none !important;
    }
}

mdc-drawer {
    top: 0;
    z-index: 1000 !important;
}

mdc-drawer-header {
    border-bottom: 2px solid $color-grey-400;
    padding: 0 !important;
    min-height: unset !important;
}

.drawer-item {
    border-radius: 0 !important;
    margin: 0 !important;
    height: 53px !important;
    padding-left: $extrahugetitle !important;
    border-bottom: 1px solid $divisor-gray-light;

    .mdc-deprecated-list-item__ripple::before,
    .mdc-deprecated-list-item__ripple::after {
        opacity: 0 !important;
    }

    &:hover {
        background-color: $lilac-hover-medium;
    }
}

.drawer-item-expandable {
    padding-left: 0 !important;
    border-bottom: 1px solid $divisor-gray-light;
    box-shadow: none !important;

    & .mdc-expandable__header {
        padding-left: $extrahugetitle !important;

        &.mdc-expandable__header--closed,
        &.mdc-expandable__header--open {
            height: 53px !important;
            border-radius: 0;

            &:hover {
                background-color: $lilac-hover-medium;
            }
        }

        .expandable-item-arrow {
            color: $purple-text;
            transition: all 0.2s ease;
            font-size: 29px;
        }

        &.mdc-expandable__header--closed .expandable-item-arrow {
            transform: rotate(270deg);
        }

        &.mdc-expandable__header--open .expandable-item-arrow {
            transform: rotate(180deg);
        }


        &.mdc-expandable__header--focused {
            background-color: $lilac-hover;
            border-radius: 0 !important;
        }

        &.mdc-ripple-upgraded--background-focused::before {
            opacity: 0 !important;
        }

        &::before,
        &::after {
            background-color: $transparent;
        }
    }

    &.mdc-expandable--open {
        margin: 0 !important;
    }

    .mdc-expandable__content {
        padding: 0 !important;

        .drawer-subitem {
            padding: $small $extrahugetitle;

            &:hover {
                background-color: $lilac-hover-medium;
            }
        }

        .mdc-expandable__content-container-sub .mdc-expandable__content {
            padding: 0 !important;
            background-color: $search-input-background;
        }

        .item-flag {
            width: 30px;
            max-height: $largeplus;
            margin-right: 7px;
        }
    }
}

.navbar-expand-md .navbar-toggler {
    @media (max-width: $desktop) {
        display: inline-block !important;
    }
}

#cx-reading-progress-bar {
    margin-top: 60px;
    position: fixed;
    height: 7px;
    z-index: 900;
    top: 0;
    left: 0;

    @media (max-width: $phone) {
        margin-top: 52px;
    }

    #reading-progress-fill {
        height: 7px;
        border-top: 1px solid $white;
        -webkit-transition: width 100ms ease;
        -o-transition: width 100ms ease;
        transition: width 100ms ease;
        background: linear-gradient(90.12deg, #4300A9 2.45%, #00338C 101.9%);
    }
}

.dynamic-dropdown-content {
    height: fit-content;
    background-color: $white;
    
    &.scrolled {
        border-bottom: 1px solid $gray-input-focused;
    }
}

.links-scroll-down {
    height: 100%;
    &:hover {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 2px;
            background-color: $white;
        }
    }
}

.mobile-navbar-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $medium;
    padding: 12px 20px;
    background-color: $color-grey-100;

    .close-icon {
        @include m.square($medium);
        font-size: $largeplus;
        margin-bottom: 2px;
        padding: 2px;
        color: $purple-text;
        --md-icon-size: 12px;
    }

    .menu-button {
        @include m.font($medium, 500, $largeplus, 0.0025em);
        margin-left: 2px;
        color: $purple-text;

        @media (width <= $phone) {
            font-weight: 500;
        }
    }
}

.banner-text span:hover, 
.banner-text a:hover {
    text-decoration: underline;
}

#mobile-navbar-drawer-content {
    background-color: $color-grey-100;
}
