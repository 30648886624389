@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#vgs-form {
    form {
        margin: 0 auto;
    }

    .circle-icon-cvv {
        right: 120px;
    }

    .form-field {
        width: 100%;
        height: 50px;
        position: relative;
        margin-bottom: 10px;
        border-radius: 4px;
        
        .card-number-input {
            color: v.$white;
            font-size: v.$medium;
        }
    }

    .submit-button {
        border: unset;
        border-radius: 4px;
    }

    .mdc-dialog {
        &__content {
            .html-content-wrapper {
                width: 100%;
                height: 350px;
                z-index: 2;
            }
            ds-circular-progress {
                position: absolute;
                width: 80px;
                height: 80px;
            }
        }
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    .form-field-group {
        display: flex;
        flex-flow: wrap;
    }

    .form-field-group div {
        flex: 0 0 50%;
    }

    .form-field-group div:first-child {
        border-radius: 4px 0 0 4px;
    }

    .form-field-group div:last-child {
        border-radius: 0 4px 4px 0;
    }

    .card-details {
        background: v.$hoverbackground;
        border-radius: 3px;
        color: v.$white;
        height: 50px;
    }

    .helper-text {
        display: block;
        color: rgba(220, 53, 69, 1);
        font-size: 14px;
        height: 12px;
        text-transform: capitalize;

        @media (max-width: v.$desktop) {
            font-size: 12px;
        }
    }

    @media (min-width: v.$desktop) {
        .card-details {
            margin-top: 1.8rem;
        }
    }

    .card-img {
        max-width: 35px !important;
    }
	
    .update-button {
        height: 50px;
        margin-top: 2.8rem;
        
        @media (max-width: v.$desktop) {
            margin-top: 2.6rem;
        }
    }

    .expiry-text {
        margin-left: -45px;
        margin-top: 14px;

        @media (min-width: v.$widescreen) and (max-width: 1252px) {
            margin-left: -43px;
        }

        @media (min-width: v.$tablet) and (max-width: v.$desktop) {
            margin-left: -8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 50px;
        }

        @media (max-width: v.$tablet) {
            text-align: end;
        }
    }

    .expiry-soon-text {
        margin-left: -49px;
        margin-top: 14px;

        @media (min-width: v.$widescreen) and (max-width: 1252px) {
            margin-left: -46px;
        }

        @media (min-width: v.$tablet) and (max-width: v.$desktop) {
            margin-left: -8px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 50px;
        }

        @media (max-width: v.$tablet) {
            text-align: end;
        }
    }

    .update-expiry {
        margin-left: -17px;

        @media (min-width: v.$widescreen) and (max-width: 1252px) {
            margin-left: -15px;
        }

        @media (max-width: v.$desktop) {
            margin-left: 2px;
        }

        @media (max-width: v.$tablet) {
            margin-left: unset;
            text-align: end;
        }
    }

    .update-expiry-v2 {
        margin-left: -16px;
        
        @media (min-width: v.$widescreen) and (max-width: 1252px) {
            margin-left: -12px;
        }

        @media (max-width: v.$desktop) {
            margin-left: 2px;
        }

        @media (max-width: v.$tablet) {
            margin-left: unset;
            text-align: end;
        }
    }

    @include m.media(v.$desktop, false) {
        .card-details__text {
            @include m.truncate-text(2);
        }
    }
}
